import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import './service-inner.css'
import PageHero from '../../components/pageHero'
import servicesHero from '../../images/services-hero.png'

const VipEventsPage = () => (
  <Layout>
    <SEO title="Page two" />
    <PageHero {...{title: "VIP Events and Parties", image: servicesHero}}/>
    <div className="sp-content-wrapper flex">
      <div className="sp-content">
      <h1 className="title">WE CREATE EXPERIENCES THAT GENERATE CONVERSATION AND MEMORIES</h1>
      <p>With over 10 years of experience in the industry, we’ve seen it all. We understand that every element of your show or event, from the build-out to the beverages, must live and breathe your brand.  We tailor experiences that generate conversation and creates memories. From bespoke cocktail menus to interactive sensorial experiences, we design innovative experiences that exceed expectations. Our team has tremendous experience in building intimate affairs to large scale corperate engagements, we build special events from the ground up that will exceed expectations with innovation and trending elements.</p>
      <p>Our team has supported and built experiential events for clients at high-profile trade shows such as Tales of the Cocktail, Portland Cocktail Week, Bar Institute, Barra Mexico and Aspen Cocktail Classic, among others.</p>
      <div className="service-inner-link-anchor">
      <a href="/case-studies/">View Case Studies</a>
      </div>
      </div>
    </div>
  </Layout>
)

export default VipEventsPage
